.ee {
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
}
.ee--multiline {
  white-space: normal;
  line-height: 150%;
  max-height: 10rem;
  overflow: auto;
}
.ee[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: var(--c-placeholder);
}

.ee__v {
  color: var(--c-input);
}
.ee__v:after {
  text-transform: uppercase;
  color: var(--c-accent-2);
  content: attr(data-code);
  vertical-align: super;
  font-size: 0.7em;
}
.ee__v-unknown {
  color: var(--c-field-errors);
}
